@import "tailwindcss/tailwind.css";
@import "./styles.css";

@layer base {
    /* for reference view the video
       https://www.youtube.com/watch?v=MAtaT8BZEAo
       https://tailwindcss.com/docs/customizing-colors#using-css-variables */
    :root {
        --fontsource-display: swap;
        --color-default: 255, 255, 255;
        --color-fill: 22, 22, 22;
        --color-danger: 189, 58, 48;
        --color-base: 22, 22, 22;
        --color-light: 255, 255, 255;
        --color-dimmed: 138, 138, 138;
        --color-sub-base: 50, 50, 50;
        --color-success: 74, 183, 85;
        --color-select: 65, 65, 65;
        --color-aero: 86, 164, 255;
        --color-base-light: 36, 36, 36;
        --color-success-dark: 8, 144, 39;
        --color-wrapper: 32, 32, 32;
        --color-bg-video: 0, 0, 0;
        --color-box-shadow: #222;
        --color-plum: 101, 69, 181;
        --color-gradient: linear-gradient(90deg, rgba(192, 57, 43, 1), transparent) rgba(94, 70, 190, 1);
        --color-green-gradient: linear-gradient(90deg, rgba(10, 179, 50, 1), transparent) rgba(42, 112, 139, 1);
        --color-red-gradient: linear-gradient(90deg, rgba(171, 45, 8, 1) 0%, rgba(125, 33, 7, 1) 106%);
        --color-backdrop-gradient: linear-gradient(
            180deg,
            rgba(var(--color-fill), 0.9) 0%,
            rgba(var(--color-fill), 0.6) 100%
        );
        --color-image-gradient: linear-gradient(135deg, rgba(192, 57, 43, 0.5) 0%, rgba(94, 70, 190, 0.5) 100%);
        --color-pink: 255, 0, 101;
    }
    svg {
        @apply fill-default;
    }
    .border-right {
        border-bottom: 2px solid rgb(var(--color-light));
        border-left: 2px solid rgb(var(--color-light));
    }
}
/* scrollbar */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
}
img {
    color: rgba(var(--color-light), 0.7);
    @apply text-xs;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px #4f4f4f inset;
    -webkit-text-fill-color: white !important;
    border: 1px solid #7d7d7c;
    background-color: #4f4f4f !important;
    background-clip: content-box !important;
}
.placeholder-color {
    @apply placeholder-light/50;
}
.capitalize-first:first-letter {
    text-transform: uppercase;
}
header,
.scroll-by {
    transition: top 0.3s;
}
header {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
header .menu-item {
    @apply h-full border-b border-b-light/10 dt:border-b-0;
}
header .menu-item .more {
    @apply w-full h-14 leading-none inline-flex text-default items-center justify-between uppercase tracking-normal font-bold border-b-3 border-transparent dt:hover:border-danger focus:border-l-3 focus:border-l-danger dt:focus:border-b-danger dt:focus:border-l-0 px-5 py-4 dt:px-0 dt:py-0 dt:pt-1 text-md dt:text-sm transition-all;
}
header .menu-item .more.active {
    @apply dt:border-danger;
}
header .menu-item .dropdown-menu {
    @apply relative dt:absolute dt:mt-0 dt:border-light/10 dt:border-t pb-6 transition duration-500 ease-in-out inset-x-0 top-full opacity-100;
}
header .menu-item .dropdown-menu li.bg-dimmed {
    @apply relative bg-opacity-30 rounded-full;
}
header .menu-item .dropdown-menu li.bg-dimmed a.name {
    @apply relative normal-case text-md;
}
header .menu-item.open {
    @apply border-l-3 border-l-danger dt:border-l-0;
}
header .menu-item.open .more {
    @apply border-l-0;
}
header::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 56px;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    z-index: -1;
}
@media screen and (max-width: 1035px) {
    header.overflow-auto::before {
        height: 100%;
    }
}
.topnav-menu,
.topnav-megadrop-menu {
    margin-top: -3px;
    padding: 8px 0;
    overflow: hidden;
}
@media screen and (min-width: 1035px) {
    .topnav-menu,
    .topnav-megadrop-menu {
        background: rgba(var(--color-base-light), 0.8);
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
    }
}
@media screen and (min-width: 768px) {
    .tabs > :not(.inactive) {
        background: rgb(var(--color-light));
    }
    .search-tabs .tabs > :not(.inactive) {
        background: none;
    }
}
.search-dropdown {
    background: rgba(var(--color-base-light), 0.8);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    margin-top: -3px;
    overflow: hidden;
    max-height: 650px;
}
@media screen and (max-width: 768px) {
    .search-dropdown {
        max-height: 100vh;
        position: absolute;
        margin-top: 0;
    }
}
.search-dropdown::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
}
.search-input {
    width: 100%;
    max-width: calc(100% - 130px);
}
@media screen and (max-width: 768px) {
    .search-input {
        max-width: calc(100% - 117px);
    }
}
.box-shadow {
    box-shadow: inset 0 0 15px rgba(var(--color-light), 0.05);
}
.button-area {
    max-width: 128px;
}
.mt-extra-small {
    margin-top: -5px;
}
/* Style for custom checkbox */
.checkbox {
    margin-right: 15px;
    position: relative;
    display: block;
}

.checkbox label {
    position: relative;
    min-height: 34px;
    display: block;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    font-size: 14px;
}

.checkbox label span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.checkbox label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    transition: transform 0.28s ease;
    border-radius: 3px;
    border: 1px solid rgba(var(--color-light), 0.5);
}

.checkbox label:after {
    @apply border-right;
    content: "";
    display: block;
    width: 12px;
    height: 6px;
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    position: absolute;
    top: 6px;
    left: 4px;
    color: rgb(var(--color-light));
}

.checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
}

.checkbox input[type="checkbox"]:checked ~ label:before {
    background: rgb(var(--color-danger));
    border: 1px solid rgb(var(--color-danger));
}

.checkbox input[type="checkbox"]:checked ~ label:after {
    transform: rotate(-45deg) scale(1);
}

.checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
}

/*input[type="radio"]:not(:checked) + label .check-imagebase,
input[type="radio"]:checked + label ~ .check-image {
    display: block;
}
input[type="radio"]:not(:checked) + label .check-image,
input[type="radio"]:checked + label .check-imagebase {
    display: none;
}
input[type="radio"]:checked + label {
    border: 2px solid rgb(var(--color-success));
    background: rgba(var(--color-success), 0.25);
    &.subscription {
        border: none;
        background: rgb(var(--color-danger));
        &::after {
            width: 10px;
            height: 17px;
            margin: 0 4px;
            border-bottom: 3px solid rgb(var(--color-light));
            border-right: 3px solid rgb(var(--color-light));
            content: "";
            transform: rotate(45deg);
        }
    }
    .price {
        background: rgba(var(--color-success), 0.5);
    }
}

input[type="radio"]:not(:checked) + label {
    border: 2px solid transparent;
    &.subscription {
        border: none;
        &::after {
            content: "";
            display: block;
            transition: transform 200ms;
        }
    }
}

.check-image {
    .fa-primary {
        fill: #fff;
        opacity: 1;
    }
    .fa-secondary {
        fill: rgb(var(--color-success));
        opacity: 1;
    }
}*/

.panel a.active {
    color: rgb(var(--color-light));
    opacity: 100;
}

a.active {
    font-weight: bold;
}

a.active .chevron-down {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    color: rgb(var(--color-danger));
}

.expandContent {
    max-height: 0;
    transition: all 0.5s ease;
    overflow: hidden;
}

.filter-button {
    border: 1px solid rgba(var(--color-select));
    @apply capitalize z-0 w-full top-6 text-sm md:w-[250px] py-small z-20 rounded-3xl text-default absolute md:top-12 right-0 flex items-center justify-center;
}

@media screen and (max-width: 768px) {
    .filter-button {
        display: none !important;
    }
    .filter-button.mobileBtn {
        display: flex !important;
    }
}

.filter-button.filter-active {
    border: 1px solid rgba(var(--color-danger));
}

@media screen and (max-width: 768px) {
    .filter-button.filter-active {
        top: 26px;
        left: 16px;
        right: 16px;
        width: calc(100% - 32px);
        margin-top: 0 !important;
        display: flex !important;
    }
    .filter-button.filter-active.mobileBtn {
        display: none !important;
    }
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 64px;
    height: 1px;
    background: rgb(var(--color-light));
    opacity: 0.25;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}

.error {
    border: 1px solid rgb(var(--color-danger));
}

.series {
    filter: drop-shadow(0 0 5px rgba(22, 22, 22, 0.6));
}

.hide-scroll-bar::-webkit-scrollbar {
    display: none;
}

.hide-scroll-bar {
    scrollbar-width: none;
}

/* Make photo set title not overlap when its too long */
.title-photo {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* Hamburger icon style */
.hamburger span {
    height: 2px;
    background-color: rgb(var(--color-light));
    display: block;
    margin: 4px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 1px;
}

.hamburger:focus span {
    background-color: rgb(var(--color-aero));
}

.hamburger.active span {
    background-color: rgb(var(--color-danger));
}

.hamburger.active span:nth-child(2) {
    opacity: 0;
}

.hamburger.active span:nth-child(1) {
    -webkit-transform: translateY(7px) rotate(45deg);
    -ms-transform: translateY(7px) rotate(45deg);
    -o-transform: translateY(7px) rotate(45deg);
    transform: translateY(7px) rotate(45deg);
}

.hamburger.active span:nth-child(3) {
    -webkit-transform: translateY(-5px) rotate(-45deg);
    -ms-transform: translateY(-5px) rotate(-45deg);
    -o-transform: translateY(-5px) rotate(-45deg);
    transform: translateY(-5px) rotate(-45deg);
}

.heading-shadow {
    text-shadow: 4px 2px 5px rgba(0, 0, 0, 0.3), 0 8px 13px rgba(0, 0, 0, 0.1), 0 18px 23px rgba(0, 0, 0, 0.1);
}

.drop-shadow-bottom {
    filter: drop-shadow(0px 38px 26px black);
}

.outer-glow {
    filter: drop-shadow(0px -10px 40px rgba(255, 255, 255, 0.2));
}

.drop-shadow-tv-view {
    filter: drop-shadow(0px -10px 40px rgba(94, 70, 190, 0.4));
}

.drop-shadow-gradient-inset:after {
    background: var(--color-gradient);
    content: "";
    position: absolute;
    inset: 3em;
    filter: blur(2.5em);
    z-index: 0;
    opacity: 0.3;
}

.drop-shadow-gradient-inset.shadow-opacity-50:after {
    opacity: 0.5;
}

.drop-shadow-gradient-inset.shadow-opacity-70:after {
    opacity: 0.7;
}

.drop-shadow-gradient-bottom-inset::after {
    background: var(--color-gradient);
    content: "";
    position: absolute;
    inset: 3em;
    filter: blur(2.5em);
    z-index: 0;
    opacity: 0.3;
    bottom: 0;
    top: 100px;
}

.custom-icon {
    -webkit-mask-size: cover;
    mask-size: cover;
    mask-position: center;
    background-size: contain;
}

.custom-icon.xmark-solid {
    -webkit-mask-image: url("../svg/xmark.solid.svg");
    mask-image: url("../svg/xmark.solid.svg");
}

.snickerbar-item {
    @apply grid grid-cols-12 pointer-events-auto rounded-md w-full md:w-1/2 lg:w-1/3 p-2 md:p-4 mb-2;
}

.snickerbar-item.snickerbar-success {
    @apply bg-green-700;
}

.snickerbar-item.snickerbar-error {
    @apply bg-red-700;
}

.snickerbar-item .snickerbar-icon {
    @apply w-6 h-6 flex items-center justify-center rounded-full bg-white/30;
}

.snickerbar-item .snickerbar-icon svg {
    @apply w-3 h-3 fill-white;
}

.snickerbar-item .snickerbar-message {
    @apply col-span-10 px-4 w-full w-80 font-bold;
}

.snickerbar-item .snickerbar-close {
    @apply w-6 h-6 flex items-center rounded-full bg-white/30 justify-self-end cursor-pointer px-2;
}
