@tailwind components;

@layer components {
    html,
    body {
        @apply bg-fill text-default;
        height: 100%;
    }
    ::view-transition-old(root),
    ::view-transition-new(root) {
        animation-duration: 0.3s;
    }
    .turbo-progress-bar {
        @apply bg-danger;
        height: 2px;
    }
    svg {
        @apply fill-default;
    }
    :focus-visible {
        @apply outline-0;
    }
    input {
        @apply text-light;
    }
    a:active,
    input:active {
        @apply outline-0 ring-0;
    }
    input:focus,
    select:focus,
    button:focus {
        @apply outline-0 ring-2 ring-aero/50;
    }
    h1 {
        @apply break-words inline-block;
    }
    .hover-shine {
        @apply absolute top-0 -left-full h-full w-1/2 z-10 block transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-30 group-hover:animate-shine;
    }
    .white-bg {
        background: #ffffff;
    }
    .bg-gradient {
        background: var(--color-gradient);
    }
    .bg-green-gradient {
        background: var(--color-green-gradient);
    }
    .bg-red-gradient {
        background: var(--color-red-gradient);
    }
    .bg-backdrop-gradient {
        background: var(--color-backdrop-gradient);
    }
    .bg-dim-gradient {
        background: linear-gradient(
            180deg,
            rgba(var(--color-fill), 0) 0%,
            rgba(var(--color-fill), 0.4) 10%,
            rgba(var(--color-fill), 0.85) 20%,
            rgba(var(--color-fill), 1) 35%,
            rgba(var(--color-fill), 1) 100%
        );
    }
    .bg-dim-gradient-horizontal-md {
        background: linear-gradient(
            90deg,
            rgba(var(--color-fill), 0) 0%,
            rgba(var(--color-fill), 0.8) 20%,
            rgba(var(--color-fill), 1) 100%
        );
    }
    .bg-dim-gradient-horizontal {
        background: linear-gradient(90deg, rgba(var(--color-fill), 0) 0%, rgba(var(--color-fill), 1) 100%);
    }
    .radial-gradient {
        background: radial-gradient(rgba(var(--color-base), 0.5) 0%, rgba(var(--color-base), 1) 100%);
    }
    .image-gradient {
        background: var(--color-image-gradient);
    }
    .text-stroke-danger {
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: rgb(var(--color-danger));
    }
    .m-0\.25 {
        margin: 0.06rem;
    }
    .m-0\.125 {
        margin: 0.05rem;
    }
    .m-0\.0125 {
        margin: 0.0125rem;
    }
    .mb-0\.25 {
        margin-bottom: 0.06rem;
    }
    .mb-0\.125 {
        margin-bottom: 0.05rem;
    }
    .mb-0\.0125 {
        margin-bottom: 0.0125rem;
    }
    .-ml-0\.29 {
        margin-left: -0.29rem;
    }
    .-mr-0\.29 {
        margin-right: -0.29rem;
    }
    .view-all-btn {
        @apply relative flex items-center rounded-3xl font-bold clear-both my-0 mx-auto w-auto text-center uppercase py-3.5 leading-none px-10 text-[13px] text-default;
    }
    .rating-links {
        @apply text-left text-xs lg:text-sm hidden sm:flex sm:flex-row items-center;
    }
    .rating-links .popular-liked {
        @apply text-dimmed mr-4 hover:border-b hover:border-light hover:text-default;
    }
    .rating-links .circle {
        @apply w-4 md:w-6;
    }
    .rating-links .ratings-selected {
        @apply border border-t-0 border-l-0 border-r-0 border-light text-default mr-4;
    }
    .submenu-user {
        max-height: 432px;
    }
    .submenu-user a:hover span:first-child,
    .submenu-user a:focus span:first-child {
        background: rgba(var(--color-danger), 1);
    }

    .scroll-videos,
    .scroll-photos,
    .scroll-movies,
    .scroll-studios {
        .video_container {
            @apply max-w-xs sm:max-w-none;
        }
    }
    .video_duration:before {
        @apply float-right inline-block relative sm:absolute -mt-7 sm:mt-0 sm:bottom-0 sm:right-0 mr-1 bg-base/60 px-2 py-1 mb-1 text-[9px] sm:text-xxs rounded-full;
        content: attr(data-content);
    }
    .like-button {
        @apply text-sm mr-[1px] w-fit px-4 py-2.5 font-extrabold uppercase transition duration-500 ease-in-out bg-light/20 border border-solid rounded-3xl border-light/10 hover:bg-transparent hover:border-light hover:border-opacity-20 text-center;
    }
    .like-button svg {
        @apply transition duration-500;
    }
    .like-button.like-small {
        @apply py-1 px-[7px] bg-light/20 hover:bg-light/10;
    }
    .dislike-button {
        @apply text-sm md:w-[50px] w-10 py-2.5 font-extrabold transition duration-500 ease-in-out rounded-r-3xl bg-light/20 border border-solid border-light/10 border-r-0 hover:bg-transparent hover:rounded-r-3xl hover:border-opacity-20 text-center;
    }
    .dislike-button svg {
        @apply transition duration-500;
    }
    .expand {
        @apply absolute mx-0 md:hidden text-[11px] font-extrabold px-[23px] pt-3 pb-2.5 leading-none left-2/4 ml-[-64px] -bottom-4 cursor-pointer rounded-3xl;
    }
    a.comma:after {
        content: ", ";
        margin-right: 2px;
    }
    a.comma:last-child:after {
        content: "";
    }

    .related-movies,
    .hottest-photos,
    .hottest-studios {
        @apply sm:grid sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 lg:grid-cols-6 gap-2 dt:gap-4 sm:justify-center sm:flex-wrap flex flex-nowrap overflow-hidden overflow-x-scroll sm:overflow-visible snap;
        .movie-preview,
        .photo-preview,
        .studio-preview {
            @apply relative justify-start sm:w-full sm:col-span-1 flex-none flex-col flex-nowrap sm:flex-initial block w-40 snap-start self-stretch;
        }
        @media screen and (max-width: 768px) {
            overflow-x: scroll;
        }
    }
    .genres {
        @apply flex-shrink-0 mb-2 inline-block leading-3 border border-light/20 border-solid rounded-3xl py-3 md:px-5 px-4 mr-2 text-xs font-normal normal-case transition duration-500 ease-in-out hover:bg-light/20 focus:bg-light/20 text-default;
    }
    .filter-tag {
        @apply cursor-pointer leading-none font-bold flex justify-start items-center border border-light/20 rounded-3xl h-8 px-3 transition duration-500 ease-in-out hover:bg-light/20 focus:bg-light/10;
    }
    .snap {
        scroll-snap-type: x mandatory;
    }
    .snap > *,
    .snap .snap-start {
        scroll-snap-align: start;
    }
    .mobile-links ul {
        @apply grid gap-4 grid-cols-2 px-5 py-6;
    }
    .mobile-links ul a {
        @apply font-semibold text-sm text-default text-opacity-60 flex items-center;
    }
    .animate-bounce-vertical {
        animation: bounce-vertical 1s infinite;
    }
    @keyframes bounce-vertical {
        0%,
        100% {
            transform: translateX(25%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateX(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }
    .socials-icons a {
        @apply mb-4 md:mb-0 rounded-full bg-light/20 hover:bg-danger w-11 h-11 flex items-center justify-center;
    }
    .button-visit {
        @apply text-[13px] flex px-4 mr-4 uppercase font-extrabold py-[10px] md:py-[15px] leading-none rounded-3xl text-white relative pl-10 md:pl-14;
    }
}
